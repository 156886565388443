import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M2780 3787 c0 -9 -8 -17 -17 -18 -14 0 -15 -2 -3 -6 8 -3 18 -11 22
-17 5 -8 8 -7 8 2 0 6 10 12 23 13 14 0 17 2 7 6 -8 3 -21 12 -27 21 -12 14
-13 14 -13 -1z"/>
<path d="M3146 3665 c-12 -95 -8 -92 -89 -100 l-72 -8 65 -12 c36 -6 70 -14
76 -18 7 -4 15 -37 20 -74 l8 -68 10 69 c7 42 17 72 26 77 8 4 44 12 80 16
l65 9 -78 8 c-86 9 -78 0 -94 106 l-9 55 -8 -60z"/>
<path d="M2473 3645 c-81 -22 -126 -49 -175 -103 -52 -58 -68 -105 -68 -195 0
-63 31 -177 48 -177 9 0 196 62 201 66 2 2 -14 25 -36 51 -72 86 -80 172 -22
238 59 67 185 85 275 39 50 -26 104 -104 104 -150 0 -24 5 -34 15 -34 12 0 15
13 15 58 0 31 3 87 6 123 l7 66 -49 13 c-77 19 -260 23 -321 5z"/>
<path d="M2945 3269 c-296 -30 -682 -147 -975 -294 -117 -59 -271 -150 -345
-205 -115 -85 -185 -162 -96 -106 57 36 300 157 424 211 135 58 400 155 425
155 22 0 214 -151 257 -201 134 -159 50 -360 -150 -360 -80 0 -134 20 -182 67
-43 43 -73 116 -73 177 0 45 -24 27 -32 -24 -12 -79 -9 -236 5 -246 64 -51
320 -66 431 -25 181 66 269 235 222 422 -20 76 -48 123 -124 203 -30 31 -53
57 -51 57 2 0 58 7 124 15 204 26 394 16 475 -23 128 -62 24 -248 -215 -382
-99 -56 -71 -49 59 14 200 97 348 210 382 291 77 186 -164 295 -561 254z"/>
<path d="M2041 3178 c0 -9 -9 -22 -18 -27 -17 -10 -17 -10 0 -11 9 0 17 -5 17
-10 0 -6 6 -5 14 2 8 7 10 15 6 18 -4 3 -10 14 -13 25 -4 15 -5 16 -6 3z"/>
<path d="M1290 1973 c0 -191 4 -354 9 -361 5 -9 26 -12 67 -10 l59 3 5 125 c7
155 24 190 93 190 48 0 57 -25 57 -166 0 -88 4 -134 12 -142 7 -7 38 -12 70
-12 l58 0 0 158 c0 178 -9 209 -70 250 -45 31 -130 28 -177 -6 -17 -12 -33
-22 -36 -22 -3 0 -7 54 -9 119 -3 102 -6 124 -24 147 -24 33 -60 60 -91 69
l-23 6 0 -348z"/>
<path d="M2390 1973 c0 -191 4 -354 9 -361 5 -9 26 -12 67 -10 l59 3 3 88 c1
48 6 87 11 87 4 0 40 -40 79 -90 l72 -90 85 0 c51 0 85 4 85 10 0 6 -43 56
-95 112 l-96 102 91 91 c49 50 90 94 90 98 0 4 -38 7 -85 7 l-85 0 -67 -80
c-38 -44 -71 -80 -75 -80 -4 0 -8 81 -10 179 -3 163 -5 182 -24 207 -24 33
-60 60 -91 69 l-23 6 0 -348z"/>
<path d="M866 2245 c-88 -31 -131 -93 -131 -187 0 -97 47 -140 205 -183 110
-30 130 -46 130 -102 0 -43 -30 -63 -95 -63 -60 0 -91 19 -111 66 l-14 34 -70
0 c-77 0 -77 0 -59 -63 14 -53 71 -112 127 -134 66 -25 182 -23 242 4 84 38
120 93 120 183 0 108 -47 155 -190 190 -122 31 -150 49 -150 96 0 8 11 26 25
39 47 48 143 23 159 -40 6 -23 10 -25 71 -25 l65 0 0 33 c0 60 -62 130 -139
156 -46 15 -137 13 -185 -4z"/>
<path d="M1933 2024 c-51 -19 -108 -79 -122 -131 -30 -105 5 -220 81 -271 78
-51 203 -39 262 27 l19 21 38 -35 c23 -20 50 -35 64 -35 l25 0 0 215 c0 188
-2 215 -15 215 -25 0 -72 -28 -89 -51 l-14 -21 -39 30 c-64 51 -134 62 -210
36z m161 -108 c69 -29 81 -147 19 -195 -54 -44 -142 -14 -159 54 -10 39 2 95
27 121 19 20 31 26 72 33 4 0 23 -5 41 -13z"/>
<path d="M3076 2029 c-98 -24 -155 -92 -164 -196 -9 -112 52 -207 150 -234 60
-16 164 -7 215 20 119 60 145 245 49 349 -50 55 -163 83 -250 61z m133 -124
c55 -47 49 -164 -9 -195 -72 -39 -150 11 -150 96 0 109 84 161 159 99z"/>
<path d="M3608 2026 c-100 -27 -158 -104 -158 -212 0 -72 24 -128 73 -171 46
-41 103 -56 188 -51 131 7 213 92 213 222 -1 106 -56 181 -156 211 -61 18 -97
18 -160 1z m141 -121 c54 -46 49 -164 -9 -195 -50 -27 -118 -9 -139 37 -16 34
-14 99 5 134 27 53 94 64 143 24z"/>
<path d="M4012 1818 l3 -213 59 -3 c41 -2 62 1 67 10 5 7 9 61 9 120 0 59 5
118 11 132 14 31 61 48 106 40 l34 -6 -3 63 c-3 62 -4 64 -32 67 -46 6 -78 -7
-112 -44 -32 -33 -33 -34 -44 -14 -9 17 -77 60 -95 60 -3 0 -4 -96 -3 -212z"/>
<path d="M2410 1275 c0 -37 4 -65 10 -65 6 0 10 28 10 65 0 37 -4 65 -10 65
-6 0 -10 -28 -10 -65z"/>
<path d="M1940 1270 c0 -56 2 -60 23 -60 36 0 67 27 67 60 0 37 -24 60 -61 60
-29 0 -29 0 -29 -60z m58 28 c16 -16 15 -33 -4 -52 -25 -25 -34 -19 -34 24 0
41 14 52 38 28z"/>
<path d="M2050 1320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2200 1320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2510 1275 c-21 -52 -20 -91 1 -52 6 9 21 17 34 17 13 0 28 -8 34
-17 5 -10 13 -15 17 -11 10 11 -36 118 -51 118 -7 0 -23 -25 -35 -55z m50 -7
c0 -5 -7 -8 -15 -8 -17 0 -18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
<path d="M2247 1297 c-9 -25 8 -82 26 -85 9 -2 17 1 17 7 0 6 -4 11 -10 11 -5
0 -10 9 -10 20 0 11 5 20 10 20 6 0 5 8 -2 20 -15 23 -24 25 -31 7z"/>
<path d="M2050 1251 c0 -23 5 -41 10 -41 6 0 10 15 10 34 0 19 -4 38 -10 41
-6 4 -10 -10 -10 -34z"/>
<path d="M2108 1279 c-33 -19 -15 -69 24 -69 10 0 18 -5 18 -11 0 -5 -8 -9
-18 -7 -11 2 -21 -1 -23 -7 -7 -17 38 -17 55 0 21 21 22 95 2 95 -8 0 -20 2
-28 5 -7 2 -21 0 -30 -6z m48 -20 c9 -15 -11 -33 -30 -26 -9 4 -13 13 -10 22
7 17 30 20 40 4z"/>
<path d="M2207 1283 c-11 -10 -8 -73 3 -73 6 0 10 18 10 40 0 41 -2 45 -13 33z"/>
<path d="M2311 1277 c-15 -15 -14 -42 2 -54 6 -6 27 -10 45 -10 30 0 32 2 32
36 0 34 -2 36 -34 38 -18 1 -39 -4 -45 -10z m54 -27 c0 -8 -8 -16 -17 -18 -13
-2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z"/>
<path d="M2622 1278 c-25 -25 -6 -68 30 -68 10 0 18 -5 18 -11 0 -5 -8 -9 -18
-7 -11 2 -21 -1 -23 -7 -7 -16 47 -17 60 -1 7 8 11 33 9 57 -3 39 -5 42 -34
45 -17 2 -36 -2 -42 -8z m51 -23 c7 -17 -8 -29 -28 -21 -17 6 -9 36 10 36 7 0
15 -7 18 -15z"/>
<path d="M2730 1275 c-25 -31 9 -76 46 -60 17 8 16 9 -8 13 -35 4 -37 22 -3
22 28 0 34 24 9 34 -23 9 -30 8 -44 -9z m35 -5 c3 -5 -1 -10 -10 -10 -9 0 -13
5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z"/>
<path d="M2810 1249 c0 -44 17 -53 22 -11 2 16 9 27 18 27 9 0 16 -11 18 -27
2 -16 8 -28 13 -28 11 0 12 69 2 71 -69 10 -73 8 -73 -32z"/>
<path d="M2928 1279 c-33 -19 -15 -69 24 -69 24 0 23 16 -2 23 -11 3 -20 10
-20 17 0 7 9 14 20 17 22 6 28 23 8 23 -7 0 -21 -5 -30 -11z"/>
<path d="M3039 1268 c-11 -20 -13 -20 -24 -5 -21 28 -37 20 -18 -9 13 -21 15
-32 6 -55 -9 -22 -8 -29 1 -29 10 0 66 98 66 115 0 14 -21 2 -31 -17z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
